import React from 'react';

export default class NotificationsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsCount: 0,
      notificationsList: [],
      singleNotification: false,
    };
    this.loadingMore = false;
    this.seeAll = false;
    this.handleSeeAll = this.handleSeeAll.bind(this);
    this.handleProgressiveLoading = this.handleProgressiveLoading.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleNotificationDelete = this.handleNotificationDelete.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
  }

  componentDidMount() {
    if (typeof checkNotifications === 'function') checkNotifications();
  }

  handleClearAll() {
    if (this.state.notificationsList.length > 0) {
      var that = this;
      var clearBtn = event.target;
      $(clearBtn).prop('disabled', true);
      $.ajax({
        url: '/archive_notifications_redesign',
        dataType: 'JSON',
        data: { notification_ids: 'all' },
        success: function () {
          $(clearBtn).prop('disabled', false);
          that.seeAll = false;
          $('#notifications-icon').removeClass('pm-icon-alert');
          that.setState(
            { notificationsList: [], notificationsCount: 0 },
            function () {
              $('#notification-alert-amount').hide();
            }
          );
        },
      });
    }
  }

  handleProgressiveLoading() {
    var that = this;
    var list = $('.pm-notifications--list', '#notifications-container');
    if (
      list[0].scrollHeight - list.scrollTop() <=
      $('.pm-notification:last').height() + list.outerHeight()
    ) {
      if (
        !that.loadingMore &&
        that.state.notificationsCount > that.state.notificationsList.length &&
        that.state.notificationsList.length > 0
      ) {
        startLoadingSpinner(
          'notifications-container .loading-spinner-container'
        );
        that.loadingMore = true;
        $.ajax({
          url: '/notifications/progressive_list_redesign',
          dataType: 'JSON',
          data: {
            offset_notification_id:
              that.state.notificationsList[
                that.state.notificationsList.length - 1
              ].id,
            render_limit: 20,
          },
          success: function (data) {
            var tmpNotificationsList = JSON.parse(
              JSON.stringify(that.state.notificationsList)
            );
            that.setState(
              {
                notificationsList: tmpNotificationsList.concat(
                  data.notificationsList
                ),
              },
              function () {
                that.loadingMore = false;
                stopLoadingSpinner(
                  'notifications-container .loading-spinner-container'
                );
              }
            );
          },
        });
      }
    }
  }

  handleSeeAll(event) {
    this.seeAll = true;
    checkNotifications();
  }

  handleNotificationDelete(event, nId) {
    var that = this;
    if (event != null)
      var notificationId = $(event.currentTarget).data('notification-id');
    else if (event == null && nId != null) var notificationId = nId;

    $.ajax({
      url: '/archive_notifications_redesign',
      data: { notification_ids: [notificationId] },
      dataType: 'JSON',
      success: function () {
        that.seeAll = false;
        var notificationsList = [];
        JSON.parse(JSON.stringify(that.state.notificationsList)).forEach(
          function (n) {
            if (n.id != notificationId) notificationsList.push(n);
          }
        );
        if (that.state.notificationsCount - 1 == 0)
          $('#notifications-icon').removeClass('pm-icon-alert');
        that.setState(
          {
            notificationsList: notificationsList,
            notificationsCount: that.state.notificationsCount - 1,
          },
          function () {
            if (that.state.notificationsCount == 0)
              $('#notification-alert-amount').hide();
            else
              $('#notification-alert-amount').text(
                that.state.notificationsCount
              );
          }
        );
      },
    });
    that.handleProgressiveLoading();
  }

  handleNotificationClick(link, remote, nId, event) {
    var that = this;
    if (!$(event.target).hasClass('pm-notification-delete')) {
      if (remote) {
        $.ajax({
          url: link,
          dataType: 'JSON',
          success: function (data) {
            initDialog(data.html);
            that.handleNotificationDelete(null, nId);
          },
        });
      } else {
        if (link != '#') {
          window.location.href = link;
        }
        that.handleNotificationDelete(null, nId);
      }
      if ($('#notifications-nav').hasClass('pm-notifications--open')) {
        $('#notifications-nav').removeClass('pm-notifications--open');
      }
    }
  }

  render() {
    var that = this;
    var notificationsList = [];

    if (typeof this.state.notificationsList != 'undefined') {
      notificationsList = this.state.notificationsList.map(function (
        notification
      ) {
        //TODO: new interface would like to show as days ago..
        var notificationDate = new Date(notification.date).toLocaleDateString();

        //TODO: Determine the notification dot color based on wheather it is read and the type of notifications
        // "pm-notification--regular ", "pm-notification--critical ", "pm-notification--alert "
        var notificationClass = 'pm-notification--regular ';
        var readClass = true ? 'pm-unread' : 'pm-read';

        return (
          <div
            key={'notification' + notification.id}
            className={
              'pm-notification pm-flex pm-flex-nowrap ' +
              notificationClass +
              readClass
            }
            data-href={notification.link}
            data-remote={notification.remote}
            onClick={that.handleNotificationClick.bind(
              this,
              notification.link,
              notification.remote,
              notification.id
            )}
            style={{ cursor: 'pointer' }}
          >
            <i className={'mdi ' + notification.iconClassName}></i>
            <div
              className="pm-notification--copy"
              data-href={notification.link}
              data-remote={notification.remote}
            >
              <h4>
                <a
                  data-href={notification.link}
                  data-remote={notification.remote}
                >
                  {notification.title}
                </a>
              </h4>
              {notification.description != null && (
                <p className="pm-notification-description">
                  {notification.description + '\n'}
                </p>
              )}
              <span>{notificationDate}</span>
            </div>
            <button
              className="mdc-button mdc-button-icon pm-text-color--grey6 mdc-button-row pm-account-menu notification-delete-btn"
              data-notification-id={notification.id}
              data-mdc-auto-init="MDCRipple"
              onClick={that.handleNotificationDelete}
            >
              <i className="mdi mdi-close pm-notification-delete"></i>
            </button>
          </div>
        );
      });
    }
    return (
      <div
        id="notification-list"
        className="pm-notifications pm-surface mdc-elevation--z4 pm-bg-color--white"
        style={{ maxHeight: 'inherit' }}
      >
        {!this.state.singleNotification && (
          <div className="notifications-header">
            <div className="pm-surface--header pm-flex pm-flex-space-between">
              <h3>Notifications</h3>
              <span className="pm-flex-grow"></span>
              {this.state.notificationsCount > 0 && (
                <button
                  className="mdc-button pm-text-color--red1 mdc-button--dense mdc-button--compact"
                  data-testing-id="clear-all-notifications-button"
                  data-mdc-auto-init="MDCRipple"
                  onClick={this.handleClearAll}
                >
                  Clear All
                </button>
              )}
            </div>
          </div>
        )}
        {this.state.notificationsCount == 0 && (
          <div id="no-notifications">
            <div className="pm-surface--header pm-text-center">
              <i
                className="mdi mdi-bell pm-text-size-24"
                style={{ color: '#B3C1CC' }}
              ></i>
              <h3 className="pm-text-color--grey5 pm-padding-top-16 pm-text-size-16">
                No New Notifications
              </h3>
            </div>
          </div>
        )}
        {this.state.notificationsCount > 0 && (
          <div
            className="pm-notifications--list"
            onScroll={this.handleProgressiveLoading}
          >
            {notificationsList}
            <div className="loading-spinner-container"></div>
          </div>
        )}
      </div>
    );
  }
}
